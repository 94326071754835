import React from "react"

const Avatar = ({ name, title, pic, mail, mail2 }) => {
  return (
    <div className="column is-one-third">
      <div className="media">
        <figure className="media-left image is-128x128">
          <img className="is-rounded" src={pic} alt="Profile" />
        </figure>
        <div className="media-content">
          <div className="content">
            <div style={{ margin: "auto", padding: "1.5em 0px 0px 0px" }}>
              <strong className="title is-5">{name}</strong>
              <br />
              <strong className="subtitle is-5">{title}</strong>
              <br />
              {mail && (
                <small>
                  <a href={`mailto:${mail}`}>{mail}</a>
                </small>
              )}
              {mail2 && (
                <>
                  <br />
                  <small>
                    <a href={`mailto:${mail2}`}>{mail2}</a>
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Avatar
