import React from "react"
import SmallHero from "../../components/layout/smallHero"
import Avatar from "../../components/Avatar"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout/layout"
import Trainer from "../../img/illustration/trainer.svg"

import levionnoisMarcAntoine from "../../img/membres/levionnoisMarcAntoine.png"
import baoluu from "../../img/membres/baoluu.jpg"
import melissa from "../../img/membres/melissa.png"
import melanie from "../../img/membres/melanie.png"
import heloisecoen from "../../img/membres/heloisecoen.jpg"

import "../mystyles.scss"

const Trainers = [
  {
    name: "Marc-Antoine Levionnois",
    title: "Groupe Noir et Bleu",
    pic: levionnoisMarcAntoine,
  },
  {
    name: "Quoc Bao Luu",
    title: "Groupe Rouge",
    pic: baoluu,
  },
  {
    name: "Héloïse Coen",
    title: "Groupe Vert",
    pic: heloisecoen,
  },
  {
    name: "Mélanie Pereira Carvalho",
    title: "Groupe Enfant",
    pic: melanie,
  },
  {
    name: "Mélissa Levionnois",
    title: "Groupe Enfant",
    pic: melissa,
  },
]

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Les entraineurs</title>
      </Helmet>
      <SmallHero title="Les entraineurs" color="is-warning" />
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered">Recontrez les entraineurs</h1>
          <div className="columns">
            <div className="column is-one-fifth">
              <figure class="image">
                <img src={Trainer} alt="placeholder illustration" />
              </figure>
            </div>
            <div className="column ">
              <div className="columns is-multiline ">
                {Trainers.map(({ name, title, pic }) => (
                  <Avatar name={name} title={title} pic={pic} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
